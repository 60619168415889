import * as React from "react"
import styled from "styled-components"

export interface CircleIconProps {
  icon: string;
  bgColor?: string;
  className?: string;
}

const CircleIcon: React.FunctionComponent<CircleIconProps> = (props: CircleIconProps) => {
  const {
    icon,
    className
  } = props;


  return (
    <section className={className}>
      <img src={icon} alt="circleIcon" />
    </section>
  );
};

const StyledCircleIcon = styled(CircleIcon)<CircleIconProps>`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.bgColor || "#E7EBFC")};
  img {
    width: 24px;
    height: 24px;
  }
`;

export default StyledCircleIcon;
