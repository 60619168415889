
import SP1 from "assets/image/speciality-1.png"
import SP2 from "assets/image/speciality-2.png"
import SP3 from "assets/image/speciality-3.png"
import SP4 from "assets/image/speciality-4.png"
import SP5 from "assets/image/speciality-5.png"
import SP6 from "assets/image/speciality-6.png"
import SP7 from "assets/image/speciality-7.png"
import SP8 from "assets/image/speciality-8.png"
import SP9 from "assets/image/speciality-9.png"

export interface IList {
  icon: string,
  title: string,
  desc: string,
}
export const SPECIAL_LIST: Array<IList> = [
  {
    icon: SP1,
    title: "高性能的线性可伸缩性",
    desc: "通过并行分组，线性扩展吞吐量和系统容量"
  },
  {
    icon: SP2,
    title: "毫不妥协的安全性",
    desc: "攻击单个分组和攻击全网一样困难"
  },
  {
    icon: SP3,
    title: "完全的分布式",
    desc: "不需要信标链(Beacon)，或者其它中心化的协调机制，彻底规避单点失效"
  }
]

export const PRODUCTION_LIST: Array<IList> = [
  {
    icon: SP4,
    title: "高吞吐量和系统容量的区块链系统",
    desc: "系统负载分布在共识组中。单个节点将不会有不断增加的工作量。 任何拥有普通带宽和计算能力的计算机都可以加入网络并成为网络的节点。"
  },
  {
    icon: SP6,
    title: "可编程的签名",
    desc: "支持多种灵活的签名：单签，多签，甚至可以自定义交易验证逻辑。我们的技术方案还设计了基于社交关系的密钥恢复机制和企业密钥管理体系。支持多种签名算法并存，包括国密加密算法以及抗量子密码学。"
  },
  {
    icon: SP8,
    title: "链上持久化存储",
    desc: "支持将大型数据文件存储在链上，以实现稳定的持久性。这些数据不依赖于外部的存储服务，实现永久的高可用性。藉由分组共识的架构，分摊存储和服务的工作量，不会在单个节点上累积工作负担。"
  },
  {
    icon: SP5,
    title: "并行中继执行的系统架构",
    desc: "为了适配并行中继执行的系统架构，我们设计实现了PREAL语言(Parallel Relayed Execution Architecture Language) 。PREAL语言支持函数编程模型，可轻松定义异步跨组交易逻辑和状态。"
  },
  {
    icon: SP7,
    title: "基于共识的随机数发生器",
    desc: "墨群链基于区块共识的熵，构造随机数生成器，为智能合约提供使用随机数的能力。其中安全随机数可以杜绝区块创建者对随机数种子的操控，实现安全的链上随机行为。"
  },
  {
    icon: SP9,
    title: "链上名字空间",
    desc: "墨群链附带了一个内置的可编程名字空间系统，该系统允许以用户友好的形式命名地址，合约和DApp，也可以解析为任意类型的信息(如 IP地址)，并以去中心化的方式完成解析。"
  },
]