import React from "react"
import logo from "assets/image/logo.png"
import footerLogo from "assets/image/footer-logo.png"
import groupImg from "assets/image/group-img.png"
import academicImg from "assets/image/academic-img.png"
import specialityBG2 from "assets/image/speciality-bg2.png"
import specialityBG1 from "assets/image/speciality-bg1.png"
// import emial from "assets/image/email.png"
import plc from "assets/image/plc.png"
import CircleIcon from "components/CircleIcon"
import { SPECIAL_LIST, PRODUCTION_LIST } from 'constants/index'
import { Toast } from "antd-mobile"
import "./index.scss"

const { useState } = React

function Home() {
  const [showMore, setShowMore] = useState(false)
  const handleClickShowMore = () => {
    setShowMore(!showMore)
  }

  const handleClickDownload = () => {
    Toast.show({ 
      content: "手机端不支持下载，请前往电脑端登录下载",
      maskClassName: "toast-mask",
      duration: 2000
    })
  }

  return (
    <div className="home">
      <header className="header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          墨群链开放平台
          <span className='version'>Beta</span>
        </p>
      </header>
      <nav className="nav">
        <h2>释放可计算信任的无限潜能</h2>
        <p>基于异步共识组的高性能区块链系统</p>
      </nav>
      <div className="lang speciality-box">
        <div className="bg">
          <img src={specialityBG1} alt="" />
        </div>
        <p className="s-title">PREDA 区块链编程语言套装</p>
        <p className="s-detail">能够并行区块链合约编程语言编译和执行系统；拥有完全国内自主知识产权；开发环境支持全桌面平台</p>
        <div className="s-btn" onClick={handleClickDownload}>请前往电脑端登录下载</div>
      </div>
      <div className="break speciality-box">
        <p className="s-title">突破区块链的三难困境</p>
        <p className="s-detail">
          实现一个高性能的区块链系统是一个巨大的挑战，以至于有人甚至认为它是不可能的。人们发明了很多类型各异的共识算法尝试解决这个难题，但还未能达成本质上的提升。墨群区块链从一个独特的角度解构问题，突破了这个三难困境。我们的解决方案是：使用异步共识组将
          {
            showMore
              ? (<span>整个网络的工作量进行切分。全网交易在各个分组中没有重复地并行处理，记账和维护。从而获得吞吐量和状态容量随着分组数量上升而线性增长，吞吐量可以轻松到达万级每秒以上，并可承载数十亿数量级以上的用户。这个方案超越了传统的区块链分片模式，再也无需依赖于信标链(Beacon)或者根链。所有的分组都是平等，独立，同质和完全分布式的。</span>)
              : '...'
          }
        </p>
        <div className="s-btn" onClick={handleClickShowMore}>{ showMore ? '收起' : '查看更多'}</div>
      </div>
      <div className="special speciality-box">
        <p className="s-title">特性</p>
        <div className="bg">
          <img src={specialityBG2} alt="" />
        </div>
        <ul>
          {SPECIAL_LIST.map(item => (
            <li className="special-item" key={item.icon}>
              <CircleIcon icon={item.icon} className="sp-icon" />
              <div className="sp-text">
                <p className="sp-title">{item.title}</p>
                <p className="sp-desc">{item.desc}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="group speciality-box">
        <p className="s-title">组件</p>
        <ul>
          <li className="g-item left">
            <p className="g-title">异步共识组</p>
            <p className="g-desc">我们最关键的创新是将全网的负载分割到多个互不交叠的分组。在不需要信标链(Beacon)或根链的情况下，实现了一个完整的去中心化系统，并且能够并行地处理事务，执行工作负载和维护全局状态。</p>
          </li>
          <li className="g-item right">
            <p className="g-title">最终原子性</p>
            <p className="g-desc">这是关键的共识协议，它允许在恒定开销下进行大规模并行可伸缩交易处理。为此，我们针对我们的并行计算架构，精心设计了编程语言 PREDA。</p>
          </li>
          <li className="g-item left">
            <p className="g-title">诸葛连弩共识算法</p>
            <p className="g-desc">将全网划分为多个共识组时，这个关键协议确保实现大规模并行交易处理的同时，系统的安全性如同单链系统一样可靠。</p>
          </li>
          <li className="g-item right img">
            <p className="g-title">多共识组并行出块</p>
            <img src={groupImg} alt="多共识组并行出块" />
          </li>
        </ul>
      </div>
      <div className="academic speciality-box">
        <p className="s-title">学术研究</p>
        <p className="s-detail">我们的解决方案基于论文："Monoxide: Scale out Blockchains with Asynchronous Consensus Zones"，该论文经过同行评审研究并发表在计算机网络领域顶级会议NSDI'2019。<a target="blank" href="https://kaas.moqun.cn/pdf/nsdi19-wang-jiaping.pdf" className="detail-more">阅读全文</a></p>
        <img src={academicImg} alt="学术研究" />
      </div>
      <div className="production speciality-box">
        <p className="s-title">产品特性</p>
        <ul>
          {PRODUCTION_LIST.map(item => (
            <li className="production-item" key={item.icon}>
              <CircleIcon icon={item.icon} className="p-icon" />
              <div className="p-text">
                <p className="p-title">{item.title}</p>
                <p className="p-desc">{item.desc}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <footer>
        <img className="logo" src={footerLogo} alt="kaas moqun" />
        <div className="email">
          {/* <img src={emial} alt="contact@moqun.cn" /> */}
          <p><span>联系我们：</span><a href="mailto:contact@moqun.cn">contact@moqun.cn</a></p>
        </div>
        <p className='desc'>© 2019 ，墨群计算开发团队</p>
        <div className="plc">
          <a className="block" href="https://bcbeian.ifcert.cn/index">区块链信息服务备案 3101152151296694001X号</a>
          <p>
            <img src={plc} alt="" />
            <a className="register" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502017946">沪公网安备 31011502017946</a>
            <a href="https://beian.miit.gov.cn">沪ICP备 20003139</a>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Home
