import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from 'pages/Home'
import { getUA } from "utils/ua"

const isDev = !document.domain.match('moqun.cn')
const UA = getUA()
if (!UA.isMobile) {
  window.location.replace(isDev ? 'http://139.224.213.231:3000' : 'https://www.moqun.cn')
}

const scale = document.documentElement.clientWidth / 750
document.documentElement.style.fontSize = (100 * scale) + 'px'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
