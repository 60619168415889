interface IUA {
  wxpcmini: boolean,
  wxminiprogram: boolean,
  isIOS: boolean,
  isAndroid: boolean,
  isMobile: boolean,
}
export const getUA = (agent?: string)  => {
  const ua = agent || navigator.userAgent // todo ssr需要重新设置
  const UA: IUA = {
    wxpcmini: false,
    wxminiprogram: false,
    isIOS: false,
    isAndroid: false,
    isMobile: false,
  }
  if (/miniprogramenv\/(windows|mac)/i.test(ua)) {
      UA.wxpcmini = true
  }
  if (~ua.indexOf('miniProgram')) {
      UA.wxminiprogram = true
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
    UA.isIOS = true
  }
  if (~ua.indexOf('Android') || ~ua.indexOf('Adr')) {
    UA.isAndroid = true
  }
  let Agents = ["Android", "iPhone", "webOS", "BlackBerry", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  for (let i = 0; i < Agents.length; i++) {
    if (~ua.indexOf(Agents[i])) {
      UA.isMobile = true
      break
    }
  }
  return UA
}